/* eslint-disable no-unused-vars */
import * as React from "react";
import styled from "styled-components";
import Column from "./components/Column";
import Wrapper from "./components/Wrapper";
import Header from "./components/Header";
import { QRCode } from 'react-qrcode-logo';
import bg from "./assets/bg.jpg";
import qrlogo from "./assets/walletpay-logo.png";


// 注意：這個專案是專門拿來產 qrcode 給錢包 scan 用的，會被導向真正的 url 智能合約地址

const SLayout = styled.div`
  position: relative;
  width: 100%;
  /* height: 100%; */
  min-height: 100vh;
  text-align: center;
  background-image: url(${bg});
  background-size: auto;
  background-repeat: no-repeat;

  
`;

const SContent = styled(Wrapper)`
  width: 100%;
  height: 100%;
  padding: 0 16px;
`;

const SLanding = styled(Column)`
  height: 600px;
`;

const SButtonContainer = styled(Column)`
  width: 250px;
  margin: 50px 0;

`;


const SContainer = styled.div`
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
`;

const SModalContainer = styled.div`
  width: 100%;
  position: relative;
  word-wrap: break-word;
`;

const SModalTitle = styled.div`
  
  font-size: 14px;
  font-weight: 700;
`;

const SModalParagraph = styled.p`
  font-size: 24px;
  color: #FD7028;
  float: left;
  font-weight: bold;
`;

// @ts-ignore
const SBalances = styled(SLanding)`
  height: 100%;
  & h3 {
    padding-top: 30px;
  }
`;

const STable = styled(SContainer)`
  flex-direction: column;
  text-align: left;
`;

const SRow = styled.div`
  width: 100%;
  display: flex;
  margin: 6px 0;
`;

const SKey = styled.div`
  width: 30%;
  font-weight: 700;
`;

const SValue = styled.div`
  width: 70%;
  font-family: monospace;
`;

const STestButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;


const INITIAL_STATE = {
  
  accounts: [],
  address: "",
  toAddr:"",
  orderid:"",
  amount:0,
  assets: [],
  timout:1608088700,
  mins:4,
  secs:30,
  toScanUrl:'',
  iconUrl:''
};

class MainPage extends React.Component {
    state = {
        address: "",
        toAddr:"",
        orderid:"",
        amount:0,
        redirecturl:'',
        result: null,
        assets: [],
        timout:1608088700,
        mins:0,
        secs:0,
        toScanUrl:'',
        iconUrl:''
    }

    async componentDidMount() {
        this.handleLoad()
    }

    handleLoad = async()=> {
      const query = new URLSearchParams(this.props.location.search);
      
      // 從 queryparam 獲取 address & orderid
      this.setState({ toAddr: query.get('address')})
      this.setState({ orderid: query.get('orderid')})
      this.setState({ amount: query.get('amount')})
      this.setState({ redirecturl: query.get('redirecturl')})
      this.setState({ timout: query.get('timout')})
 
      let timeout = query.get('timout')
      let now = Date.now()
      let difference = timeout - now

      if(difference<0){
        this.setState({result:'超时,请重新获取充值 ID!'})
      }else{
        let hoursDifference = Math.floor(difference/1000/60/60);
        difference -= hoursDifference*1000*60*60
        let minutesDifference = Math.floor(difference/1000/60);
        difference -= minutesDifference*1000*60
        let secondsDifference = Math.floor(difference/1000)

        this.setState({ mins: minutesDifference })
        this.setState({ secs: secondsDifference })
        this.countDownTimer(this.state.redirecturl)
      }

      let _url = 'https://wallet-pay-trc20.onrender.com/?'
      _url += 'orderid='+ query.get('orderid')+'&'
      _url += 'address='+ query.get('address')+'&'
      _url += 'amount='+ query.get('amount')+'&'
      _url += 'redirecturl='+ query.get('redirecturl')+'&'
      _url += 'timout='+ query.get('timout')
      
      this.setState({ toScanUrl: _url })


      // logo
      let _iconurl = query.get('iconUrl')
      if(!_iconurl){
        _iconurl = qrlogo
      }
      this.setState({ iconUrl: _iconurl})
    }

    countDownTimer = (redirecturl) => {
      setInterval(() => {
        
        if (this.state.secs > 0) {
          this.setState({
            secs: this.state.secs - 1
          });
        } else if (this.state.mins > 0) {
          this.setState({
            secs: 59,
            mins: this.state.mins - 1
          });
        } else {
          if(!redirecturl){
            this.setState({result:'超时,请重新获取充值 ID!'})
          }else{
            window.location.replace(redirecturl)
          }
        }
      }, 1000);
    }

    killSession = async () => {
      this.resetApp();
    }

    resetApp = async () => {
      await this.setState({ ...INITIAL_STATE });
    };

    render = () => {
      const {
        assets,
        address,
        connected,
        amount,
        mins,
        secs,
        toScanUrl,
        iconUrl
      } = this.state;
      return (
        <SLayout>
          <Column maxWidth={1000} spanHeight>
            <Header
              connected={connected}
              address={address}
              killSession={this.killSession}
              mins={ mins }
              secs={ secs }
            />
            <SContent>
              {!this.state.result ? (
                <SLanding center>
                  <SModalTitle>
                    扫一扫付款
                  </SModalTitle>
                  <SModalParagraph>
                    支付 {amount} USDT
                  </SModalParagraph>
                  <QRCode value={toScanUrl} 
                    size={300}
                    fgColor={'#2B53BD'}
                    logoImage={iconUrl}
                    quietZone={20}
                    logoWidth={100}
                    logoHeight={100}
                  />
                   <br />
                  <h3>
                    推荐使用 <a href={'https://token.im/'}  target="_blank" rel="noopener noreferrer" >imToken</a> 等钱包 App 扫描支付
                  </h3>
                  
                  
                </SLanding>
              ) : (
                <SLanding  center>
                   <h3>
                    <br />
                  </h3>
                  <SButtonContainer></SButtonContainer>
                  <SBalances>
                    <h3>{`${this.state.result}`}</h3>
                  </SBalances>
                </SLanding>
              )}
            </SContent>
          </Column>
        </SLayout>
      )
    }
}

export default MainPage;
/* eslint-enable no-unused-vars */
